var site = site || {};
var openSalesforceChat = openSalesforceChat || function () {};

site.template = site.template || {};

(function ($, Drupal) {
  Drupal.behaviors.liveChatServiceDrawerBlipV1 = {
    closeLcServiceDrawer: function ($lcServiceDrawer, $pageOverlay) {
      $lcServiceDrawer.slideUp();
      $pageOverlay.slideUp();
    },
    openLcServiceDrawer: function ($lcServiceDrawer, $pageOverlay) {
      $lcServiceDrawer.slideDown();
      $pageOverlay.slideDown();
      if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
        site.track.evtLink({
          event_name: 'live chat interaction',
          event_category: 'live chat interaction',
          event_action: 'chat offered',
          event_label: 'click'
        });
      }
    },
    blipChatbot: function (event) {
      var $blipClick = $('#blip-chat-open-iframe');

      event.preventDefault();
      if ($blipClick.length > 0) {
        $blipClick.click();
      }
    },

    attach: function (context) {
      var self = this;
      var $lcServiceDrawer = $('.js-lc-service-drawer-blip', context);
      var $pageOverlay = $('.js-lc-service-drawer-overlay', context);
      var $liveChat = $('.js-live-chat', context);
      var $beautyAdvisorBtn = $('.js-lc-beauty-advisor-btn', $lcServiceDrawer);
      var $orderStatusBtn = $('.js-lc-order-status-btn', $lcServiceDrawer);

      $liveChat.once().on('click', function (event) {
        event.stopPropagation();
        event.preventDefault();

        if ($lcServiceDrawer.is(':visible')) {
          self.closeLcServiceDrawer($lcServiceDrawer, $pageOverlay);

          return;
        }

        var $blipChat = $('#blip-chat-open-iframe');

        if ($blipChat.hasClass('opened')) {
          self.blipChatbot(event);
        }

        self.openLcServiceDrawer($lcServiceDrawer, $pageOverlay);
      });

      $('html')
        .once('service-drawer')
        .on('click', function (event) {
          if (
            !$(event.target).hasClass('js-lc-service-drawer-blip') &&
            !$(event.target).hasClass('js-live-chat')
          ) {
            self.closeLcServiceDrawer($lcServiceDrawer, $pageOverlay);
            $('html').once('service-drawer').off('click');
          }
        });

      $beautyAdvisorBtn.once().on('click', function (event) {
        self.blipChatbot(event);
      });

      $orderStatusBtn.once().on('click', function (event) {
        var $LpChat = $('.sticky-footer-chat__link');

        event.preventDefault();
        self.closeLcServiceDrawer($lcServiceDrawer, $pageOverlay);
        if ($LpChat.length > 0) {
          $LpChat[0].click();
          if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
            site.track.evtLink({
              event_name: 'live chat interaction',
              event_category: 'live chat interaction',
              event_action: 'chat type selection',
              event_label: 'Beauty Advisor'
            });
          }
        }
      });
    }
  };
})(jQuery, Drupal);
